import React, {Fragment} from 'react';
import Fade from 'react-reveal/Fade';
import { useStaticQuery, graphql } from 'gatsby';
import Image from 'gatsby-image';
import Heading from 'common/src/components/Heading';
import Text from 'common/src/components/Text';
//import SectionWrapper, { Container, ImageWrapper, TextWrapper } from './aboutUs.style';
import SectionWrapper, { Container,  ImageWrapper,  TextWrapper } from '../ArcherRxAboutUs/aboutUs.style';

const ArcherRXAboutUs = () => {
  /*
  const data = useStaticQuery(graphql`
    query {
      interiorJson {
        aboutData {
          thumb_url {
            childImageSharp {
              fluid(quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          title
          text
          text2
        }
      }
    }
  `);
  */
 const data = useStaticQuery(graphql`
 query {
   image1: file(
     relativePath: { eq: "image/archerhealth/feature/Why-Archer-Difference-min.png" }        
   ) {
       childImageSharp {
         fluid(quality: 100) {
           ...GatsbyImageSharpFluid
         }
       }
   }
 }
 `);
  //const { thumb_url, title, text, text2 } = data.interiorJson.aboutData;
  //const { thumb_url } = data.interiorJson.aboutData;
  const setTitle = title => {
    return { __html: title };
  };

  return (
    <SectionWrapper id="aboutUs">
      <Container>
        <ImageWrapper>
          <Fade left>
            <Image
              fluid={
                (data.image1 !== null) | undefined
                  ? data.image1.childImageSharp.fluid
                  : {}
              }
              alt={`The Archer Health Difference`}
              className="carousel_img"
            />
          </Fade>
        </ImageWrapper>
        <TextWrapper>
          <Fade right>
            <Heading as="h1" dangerouslySetInnerHTML={setTitle(`The Archer Health Difference`)} />
            <Text as="h2" content={`The first of its kind: an organization completely 
            focused on making onsite healthcare accessible and affordable 
            for small to mid-sized companies.`} />
            <Text content={
              <Fragment>
              
            <ul style={{marginTop: '20px', marginBottom: '20px'}}>
            <li style={{fontWeight: '500', fontSize: '1.5rem'}}><span style={{color: '#19B8E8', fontSize: '20px', fontWeight: '500'}}>&raquo;</span> No expensive buildouts</li>
            <li style={{fontWeight: '500', fontSize: '1.5rem'}}><span style={{color: '#19B8E8', fontSize: '20px', fontWeight: '500'}}>&raquo;</span> No rigid plans</li>
            <li style={{fontWeight: '500', fontSize: '1.5rem'}}><span style={{color: '#19B8E8', fontSize: '20px', fontWeight: '500'}}>&raquo;</span> No high monthly overhead</li>
            </ul>
            Our unique integration of flexible scheduling, customized services, 
            virtual care, and free prescriptions makes all the difference.
            </Fragment>}
            />
          </Fade>
        </TextWrapper>
      </Container>
    </SectionWrapper>
  );
};

export default ArcherRXAboutUs;
