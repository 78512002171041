import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Box from 'common/src/components/Box';
import Text from 'common/src/components/Text';
import Heading from 'common/src/components/Heading';
//import Button from 'common/src/components/Button';
import FeatureBlock from 'common/src/components/FeatureBlock';
import Container from 'common/src/components/UI/Container';
import Particles from '../../Agency/Particle';
//import BannerWrapper, { DiscountLabel } from './bannerSection.style';
import BannerWrapper from './bannerSection.style';

const BannerSection = ({
  row,
  col,
  title,
  btnStyle,
  description,
  discountText,
  discountAmount,
  outlineBtnStyle,
}) => {
  const ButtonGroup = () => (
    <Fragment>
      {/*<Button title="GET STARTED" {...btnStyle} />
       <Button
        title="WATCH WORKS"
        variant="textButton"
        icon={<i className="flaticon-next" />}
        {...outlineBtnStyle}
      /> */}
    </Fragment>
  );
  return (
    <BannerWrapper>
      <Particles />
      <Container>
        <Box className="row" {...row}>
          <Box className="col" {...col}>
            <h1 style={{fontSize: "55px", fontWeight: "500", margin: "0"}}><span style={{color: "#19B8E8"}}>Why</span> <span style={{color: "#3D636C"}}>Archer Health</span></h1>
            <FeatureBlock
              title={
                <Heading
                  content="Transforming employee healthcare through accessibility, innovation, and convenience"
                  {...title}
                />
              }
              description={
                <Text
                  content="Archer Health was founded on the desire to bring high-quality healthcare to small and mid-sized companies at an accessible pricepoint. We know how important superior healthcare is to attracting and retaining top talent. That's why we make it easy for companies likes yours to bring the best in healthcare onsite and on demand."
                  {...description}
                />
              }
              button={<ButtonGroup />}
            />
          </Box>
        </Box>
      </Container>
    </BannerWrapper>
  );
};

BannerSection.propTypes = {
  title: PropTypes.object,
  btnStyle: PropTypes.object,
  description: PropTypes.object,
  contentStyle: PropTypes.object,
  discountText: PropTypes.object,
  discountAmount: PropTypes.object,
  outlineBtnStyle: PropTypes.object,
};

BannerSection.defaultProps = {
  row: {
    flexBox: true,
    flexWrap: 'wrap',
    ml: '-15px',
    mr: '-15px',
    alignItems: 'center',
  },
  col: {
    pr: '15px',
    pl: '15px',
    width: ['100%', '100%', '60%', '50%'],
  },
  title: {
    fontSize: ['16px', '24px', '32px', '45px'],
    fontWeight: '300',
    color: '#3D636C',
    //letterSpacing: '-0.025em',
    letterSpacing: 0,
    mb: ['20px', '25px'],
    lineHeight: '1.31',
  },
  description: {
    fontSize: ['14px','16px','20px'],
    color: '#3D636C',
    lineHeight: '1.5',
    mb: '0',
  },
  btnStyle: {
    minWidth: ['120px', '156px'],
    fontSize: '14px',
    fontWeight: '300',
  },
  outlineBtnStyle: {
    minWidth: ['130px', '156px'],
    fontSize: '14px',
    fontWeight: '500',
    color: '#0f2137',
    p: '5px 10px',
  },
  discountAmount: {
    fontSize: '14px',
    color: '#10AC84',
    mb: 0,
    as: 'span',
    mr: '0.4em',
  },
  discountText: {
    fontSize: '14px',
    color: '#0f2137',
    mb: 0,
    as: 'span',
  },
};

export default BannerSection;
